<template>
  <v-card>
    <v-card-actions>
      <v-card-title>{{$t('t.EscalationProtocol')}}</v-card-title>
      <v-spacer />
      <v-btn
        @click="openPopUp()"
        v-if="currentLevel && $store.getters.currentUserHasPermission('AccountEndEscalation')"
      >
        <v-icon class="mr-2">{{$icon('i.EndEscalation')}}</v-icon>
        {{$t('t.Stop')}}
      </v-btn>
      <v-btn
        :loading="processingRun"
        @click="runProtocol()"
        v-if="$store.getters.currentUserHasPermission('AccountEndEscalation')"
      >
        <v-icon class="mr-2">{{$icon('i.Processes')}}</v-icon>
        {{$t('t.Run')}}</v-btn>
      <v-btn
        @click="auditProtocol()"
        v-if="canShowAudit"
      >
        <v-icon class="mr-2">{{$icon('i.Audit')}}</v-icon>
        {{$t('t.Audit')}}
      </v-btn>
    </v-card-actions>
    <v-card-subtitle v-if="!isEmpty">{{protocolName}}</v-card-subtitle>
    <v-stepper
      class="mx-4"
      v-if="currentLevel"
      elevation="0"
    >
      <v-stepper-header>
        <v-divider v-if="prevLevel && prevLevel.seq > 1" />
        <v-stepper-step
          :complete="false"
          :step="prevLevel.seq"
          v-if="prevLevel"
        >
          {{prevLevel.name}}
          <small>
            <calendar-date :date="escalationDate" />
          </small>
        </v-stepper-step>
        <v-divider v-if="prevLevel" />
        <v-stepper-step
          :complete="true"
          :step="currentLevel.seq"
        >
          {{currentLevel.name}}
          <small v-if="!nextLevel && nextLevelExpectedDate">
            <calendar-date :date="nextLevelExpectedDate" />
          </small>
          <small v-else>{{$t('t.CurrentLevel')}}</small>
        </v-stepper-step>
        <v-divider v-if="nextLevel" />
        <v-stepper-step
          :complete="false"
          :step="nextLevel.seq"
          v-if="nextLevel"
        >
          {{nextLevel.name}}
          <small>
            <calendar-date :date="nextLevelExpectedDate" />
          </small>
        </v-stepper-step>
        <v-divider v-if="!isLastLevel" />
      </v-stepper-header>
    </v-stepper>
    <v-card-subtitle v-if="!isEmpty">
      <div
        class="red--text"
        v-if="blocked"
      >
        <v-icon color="red">{{$icon('i.AlertOctagon')}}</v-icon>{{$t('t.BlockedByFollowupAndOrWorkItems')}}
      </div>
      <div
        v-if="hasDunning"
        class="orange--text"
      >
        {{$t('t.DunningAwaitingReview')}}
        <span v-if="dunningSendDate">
          {{$t('t.ExpectedToBeSent')}}
          <calendar-date
            show-time
            :date="dunningSendDate"
          />
        </span>
        <span
          v-else
          class="red--text"
        >{{$t('t.DunningSendingDisabled')}}</span>
      </div>
    </v-card-subtitle>
    <v-card-subtitle v-else>{{$t('t.NoStrategy')}}</v-card-subtitle>
    <v-dialog
      v-model="confirmEndEscalation"
      width=390
      @click:outside="closePopUp()"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>{{$t('t.EndEscalation')}}?</v-card-text>
        <v-switch
          class="ml-6"
          :label="$t('t.ClearHistory')"
          v-model="clearHistory"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="doEndEscalation()"
            :loading="processingStop"
          >{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="closePopUp()"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import auditController from '@/auditController'
import Search from '@/pages/search/controllers'

export default {
  components: {
    calendarDate: () => import('@/components/calendar-date')
  },
  computed: {
    blocked () {
      return this.nextAction?.type === 'blocked' ? this.nextAction : null
    },
    canShowAudit () {
      return this.escalation?.lastEscalationProcessorRunId != null &&
        this.$store.getters.currentUserHasPermission('AccountCanDebugProtocol')
    },
    currentLevel () {
      return this.escalation?.protocol?.levels?.current
    },
    dunningSendDate () {
      return this.escalation?.dunning?.expectedSentDate
    },
    escalation () {
      return this.account?.escalation
    },
    escalationDate () {
      return this.escalation?.protocol?.date
    },
    hasDunning () {
      return !!(this.escalation?.dunning)
    },
    isEmpty () {
      return !this.nextAction && !(this.escalation?.protocol) && !this.hasDunning
    },
    isLastLevel () {
      return !!(this.escalation?.protocol?.levels?.isLastLevel)
    },
    prevLevel () {
      return this.escalation?.protocol?.levels?.prev
    },
    nextAction () {
      return this.escalation?.nextAction
    },
    nextLevel () {
      return this.escalation?.protocol?.levels?.next
    },
    nextLevelExpectedDate () {
      return this.nextAction?.type !== 'blocked' ? this.nextAction.date : null
    },
    protocolName () {
      return this.escalation?.protocol?.name
    }
  },
  data () {
    return {
      confirmEndEscalation: false,
      clearHistory: false,
      processingStop: false,
      processingRun: false
    }
  },
  methods: {
    closePopUp () {
      this.confirmEndEscalation = false
    },
    async doEndEscalation () {
      this.processingStop = true
      const targetDocument = {
        id: this.accountId,
        type: 'accounts'
      }
      Search.getActions(targetDocument)
        .get('end-escalation-account')
        .execute([this.clearHistory], targetDocument)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.SaveCompleted'))
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch('showErrorSnackbar', this.$t(e?.response?.data?.message ?? 't.UnknowError'))
        }).finally(() => {
          this.closePopUp()
          this.clearHistory = false
          this.$emit('refresh')
        })
    },
    openPopUp () {
      this.confirmEndEscalation = true
    },
    async runProtocol () {
      this.processingRun = true
      await this.$http().post('/core/escalation/run', { accountIds: [this.accountId] })
      this.$emit('refresh')
    },
    async auditProtocol () {
      auditController.audit({
        escalationProcessorRunId: this.escalation.lastEscalationProcessorRunId
      }, { type: 'accounts', id: this.accountId })

      this.$router.push({ path: '/search' }).catch(() => { })
    }
  },
  watch: {
    account: function (v) {
      if (v) {
        this.processingStop = false
        this.processingRun = false
      }
    }
  },
  props: {
    account: Object,
    accountId: String
  }
}
</script>
